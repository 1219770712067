export const craftsmanMixin = {
    data () {
        return {
            isCraftsman: false
        }
    },
    created() {
        this.isCraftsman = [
            'craftsman-login',
            'craftsman-register'
        ].includes(this.$route.name)
    },
}
